import React, { useCallback, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from './tableBasic';
import axios, { AxiosError } from 'axios';
import { Alert, Backdrop, Box, CircularProgress, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { User, Userlists } from '../models/users';

export type UserlistProperties = {
    userlist: User[]
}

export default function UserlistTable(props: UserlistProperties) {
  const [rows, setRows]: [User[], (userlist: User[]) => void] = useState(props.userlist);
  const [page, setPage]: [number, (page: number) => void] = useState(0);
  const [rowsPerPage, setRowsPerPage]: [number, (rowsPerPage: number) => void] = useState(25);
  const [total, setTotal]: [number, (page: number) => void] = useState(0);
  const [error, setError]: [string, (error: string) => void] = useState("");
  const [loading, setLoading]: [boolean, (loading: boolean) => void] = useState(true);
  const [orderBy, setOrderBy]: [string, (orderBy: string) => void] = useState("");
  const [order, setOrder]: [boolean, (order: boolean) => void] = useState(false);

  const getUserlist = (targetPage: number, itemsByPage: number, orderBy: string = "", order: boolean = false) => {
    const baseurl = "https://share.otherthings.club/api/users/"
    let url = baseurl + "?page="+(targetPage+1)
    url += "&items_by_pages="+itemsByPage
    url += "&order="+orderBy
    url += "&direction="+Number(order)
    axios.get<Userlists>(url)
    .then(resp => {
      const data = resp.data
      setRows(data.items);
      setTotal(data.count);
      setLoading(false);
    }).catch(ex => {
      if (ex instanceof AxiosError) {
        setError("Network Error")
        setLoading(false)
      } else {
        const error = 
        ex.response.status === 404 ? "Resource Not found" : "An unexpected error has occurred";
        setError(error);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getUserlist(page, rowsPerPage, orderBy, order);
  }, [page, rowsPerPage, orderBy, order]);

    // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    rowsPerPage > rows.length ? rowsPerPage - rows.length : 0;
    
    const handleChangePage = useCallback((
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
    }, []);

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(1)
    };

    const sortRequest = useCallback(
      (event: React.MouseEvent<unknown>, property: string) => {
        let dir = false;
        if (orderBy === property) {
          dir = !order;
          setOrder(!order);
        } else {
          setOrderBy(property);
          setOrder(false);
        }
        getUserlist(page, rowsPerPage, property, dir);
    }, [orderBy, order, page, rowsPerPage]);

    const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      sortRequest(event, property);
    };

    return (
        <TableContainer component={Paper}>
          {error !== "" && (
            <Alert severity='error'>{error}</Alert>
          )}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
              <TableCell>
                  <TableSortLabel
                   active={orderBy === "id"}
                   direction={orderBy === "id" && order ? 'desc' : 'asc'}
                   onClick={createSortHandler("id")}
                  >
                    Id
                    {orderBy === "id" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel 
                   active={orderBy === "name"}
                   direction={orderBy === "name" && order ? 'desc' : 'asc'}
                   onClick={createSortHandler("name")}
                  >
                    Name
                    {orderBy === "name" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                      Video Count
                </TableCell>
                <TableCell>
                      Playlist Count
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell><a href={row.url}>{row.name}</a></TableCell>
                  <TableCell>{row.videos.length}</TableCell>
                  <TableCell>{row.playlists.length}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={3}
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      );
}