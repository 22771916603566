import { Dialog, DialogTitle, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';


export interface XspfDialogProps {
    open: boolean;
    id: number;
    onClose: () => void;
}

export default function XspfDialog(props: XspfDialogProps) {
    const { onClose, open, id } = props;
    const [xspf, setXspf] = useState("");

    useEffect(() => {
        const url = "https://share.otherthings.club/api/playlists/"+id+"/playlist.xspf"
        axios.get<string>(url)
        .then(resp => { setXspf(resp.data);})
        .catch(ex => {console.log(ex);})
    }, [id])

    const copy = () => {
        navigator.clipboard.writeText(xspf)
    }

    const copyLink = () => {
        navigator.clipboard.writeText("https://share.otherthings.club/api/playlists/"+id+"/playlist.xspf")
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
            <DialogTitle>XSPF</DialogTitle>
            <Stack direction="row" spacing={1} alignSelf={"end"}>
            <Tooltip title="Copy Link to XSPF"><IconButton onClick={copyLink}><LinkIcon/></IconButton></Tooltip>
            <Tooltip title="Copy Content"><IconButton onClick={copy}><ContentCopyIcon/></IconButton></Tooltip>
            </Stack>
            <TextField multiline fullWidth value={xspf}></TextField>
        </Dialog>
    );
}