import { Dialog, DialogTitle, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export interface VideolinksDialogProps {
    open: boolean;
    id: number;
    onClose: () => void;
}

export default function VideolinksDialog(props: VideolinksDialogProps) {
    const { onClose, open, id } = props;
    const [videolinks, setVideolinks] = useState("");

    useEffect(() => {
        const url = "https://share.otherthings.club/api/playlists/"+id+"/videos_links"
        axios.get<string>(url)
        .then(resp => { setVideolinks(resp.data);})
        .catch(ex => {console.log(ex);})
    }, [id])

    const copy = () => {
        navigator.clipboard.writeText(videolinks)
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
            <DialogTitle>Video Links</DialogTitle>
            <Stack direction="row" spacing={1} alignSelf={"end"}>
                <Tooltip title="Copy Content"><IconButton onClick={copy}><ContentCopyIcon/></IconButton></Tooltip>
            </Stack>
            <TextField multiline fullWidth value={videolinks}></TextField>
        </Dialog>
    );
}