import React, { useCallback, useEffect, useState} from 'react';
import { PlaylistNoVideo, Playlists } from '../models/playlists';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from './tableBasic';
import axios, { AxiosError } from 'axios';
import { Alert, Backdrop, Box, Button, CircularProgress, Container, MenuItem, Select, SelectChangeEvent, Stack, TableCell, TableHead, TableSortLabel, TextField } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import XspfDialog from './xspfDialog';
import VideolinksDialog from './videolinksDialog';

export type PlaylistProperties = {
    playlists: PlaylistNoVideo[]
}

export default function PlaylistTable(props: PlaylistProperties) {
  const disableMaxCount: number = 99999999
  const disableMinCount: number = 0

  const [rows, setRows]: [PlaylistNoVideo[], (playlists: PlaylistNoVideo[]) => void] = useState(props.playlists);
  const [page, setPage]: [number, (page: number) => void] = useState(0);
  const [rowsPerPage, setRowsPerPage]: [number, (rowsPerPage: number) => void] = useState(25);
  const [total, setTotal]: [number, (page: number) => void] = useState(0);
  const [error, setError]: [string, (error: string) => void] = useState("");
  const [loading, setLoading]: [boolean, (loading: boolean) => void] = useState(true);
  const [orderBy, setOrderBy]: [string, (orderBy: string) => void] = useState("");
  const [order, setOrder]: [boolean, (order: boolean) => void] = useState(false);
  const [openXspf, setOpenXspf]: [boolean, (openXspf: boolean) => void] = useState(false);
  const [openVideolinks, setOpenVideolinks]: [boolean, (openVideolinks: boolean) => void] = useState(false);
  const [playlistID, setPlaylistID]: [number, (playlistID: number) => void] = useState(0);
  const [range, setRange]: [string, (range: string) => void] = useState("")
  const [search, setSearch]: [string, (search: string) => void] = useState("")
  const [minCount, setMinCount]: [number, (minCount: number) => void] = useState(disableMinCount)
  const [maxCount, setMaxCount]: [number, (maxCount: number) => void] = useState(disableMaxCount)

  const getPlaylists = (
    targetPage: number,
    itemsByPage: number, 
    orderBy: string = "", 
    order: boolean = false, 
    search: string = "", 
    range: string = "",
    minCount: number = disableMinCount, 
    maxCount: number = disableMaxCount,
  ) => {
    const baseurl = "https://share.otherthings.club/api/playlists/"
    let url = baseurl + "?page="+(targetPage+1)
    url += "&items_by_pages="+itemsByPage
    url += "&order="+orderBy
    url += "&direction="+Number(order)
    url += "&filter_count_min=" + Number(minCount)
    url += "&filter_count_max=" + Number(maxCount)
    if (search !== '') {
      switch(range) {
        case 'left':
          url += "&filter_name=%25" + search
          break
        case 'right':
          url += "&filter_name=" + search + "%25"
          break
        case 'both':
          url += "&filter_name=%25" + search + "%25"
          break
        default:
          url += "&filter_name=" + search
      }
    }
    axios.get<Playlists>(url)
    .then(resp => {
      const data = resp.data
      setRows(data.items);
      setTotal(data.count);
      setLoading(false);
    }).catch(ex => {
      if (ex instanceof AxiosError) {
        setError("Network Error")
        setLoading(false)
      } else {
        const error = 
        ex.response.status === 404 ? "Resource Not found" : "An unexpected error has occurred";
        setError(error);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getPlaylists(page, rowsPerPage, orderBy, order, search, range, minCount, maxCount);
  }, [page, rowsPerPage, orderBy, order, search, range, minCount, maxCount]);

    // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    rowsPerPage > rows.length ? rowsPerPage - rows.length : 0;
    
    const handleChangePage = useCallback((
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
    }, []);

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(1)
    };

    const createShowXspf = (id: number) => (event: React.MouseEvent<unknown>) => {
        showXspf(event, id);
    };
    
    const createShowVideoLinkList = (id: number) => (event: React.MouseEvent<unknown>) => {
      showVideoLinkList(event, id);
    };

    const showXspf = (event: React.MouseEvent<unknown>, id: number) => {
      setPlaylistID(id);
      setOpenXspf(true)
    }

    const showVideoLinkList = (event: React.MouseEvent<unknown>, id: number) => {
      setPlaylistID(id);
      setOpenVideolinks(true);
    }

    const onCloseXspf = () => {
      setOpenXspf(false)
    }

    const onCloseVideolinks = () => {
      setOpenVideolinks(false)
    }

    const sortRequest = useCallback(
      (event: React.MouseEvent<unknown>, property: string) => {
        let dir = false;
        if (orderBy === property) {
          dir = !order;
          setOrder(!order);
        } else {
          setOrderBy(property);
          setOrder(false);
        }
        getPlaylists(page, rowsPerPage, property, dir, search, range, minCount, maxCount);
    }, [orderBy, order, page, rowsPerPage, search, range, maxCount, minCount]);

    const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      sortRequest(event, property);
    };

    const handleMinCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMinCount(event.target.value === '' ? 0 : Number(event.target.value))
    }

    const handleMaxCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMaxCount(event.target.value === '' ? disableMaxCount : Number(event.target.value))
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value)
    }

    const handleSearchRangeChange = (event: SelectChangeEvent<string>) => {
      setRange(event.target.value)
    }

    const handleFilter = useCallback(() => {
      getPlaylists(page, rowsPerPage, orderBy, order, search, range, minCount, maxCount);
    }, [page, rowsPerPage, orderBy, order, search, range, minCount, maxCount]);

    return (
      <Container maxWidth="xl">
        <Stack direction="row" spacing={1} alignSelf={"center"}>
          <Select id="search-range" value={range} onChange={handleSearchRangeChange}>
            <MenuItem value="">None</MenuItem>
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="right">Right</MenuItem>
            <MenuItem value="both">Both</MenuItem>
          </Select>
          <TextField id="search" label="Search Name" value={search} onChange={handleSearchChange}/>
          <TextField id="minCountInput" label="Min Count" value={minCount} onChange={handleMinCountChange}></TextField>
          <TextField id="minCountInput" label="Max Count" value={maxCount} onChange={handleMaxCountChange}></TextField>
          <Button variant='contained' onClick={handleFilter}>Filter</Button>
        </Stack>
        <TableContainer component={Paper}>
          {error !== "" && (
            <Alert severity='error'>{error}</Alert>
          )}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <XspfDialog id={playlistID} open={openXspf} onClose={onCloseXspf}/>
          <VideolinksDialog id={playlistID} open={openVideolinks} onClose={onCloseVideolinks}/>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
              <TableCell>
                  <TableSortLabel 
                   active={orderBy === "id"}
                   direction={orderBy === "id" && order ? 'desc' : 'asc'}
                   onClick={createSortHandler("id")}
                  >
                    Id
                    {orderBy === "id" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel 
                   active={orderBy === "name"}
                   direction={orderBy === "name" && order ? 'desc' : 'asc'}
                   onClick={createSortHandler("name")}
                  >
                    Name
                    {orderBy === "name" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel 
                    active={orderBy === "count"}
                    direction={orderBy === "count" && order ? 'desc' : 'asc'}
                    onClick={createSortHandler("count")}
                    >
                      Count
                      {orderBy === "count" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null }
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  User
                </TableCell>
                <TableCell>
                  Links
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell><a href={row.url}>{row.name}</a></TableCell>
                  <TableCell>{row.count}</TableCell>
                  <TableCell>{row.user ? row.user.name : ""}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1} alignSelf={"center"}>
                      <Button variant='contained' onClick={createShowXspf(row.id)}>XSPF Playlist</Button>
                      <Button variant='contained' onClick={createShowVideoLinkList(row.id)}>VideoLink List</Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={3}
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        </Container>
      );
}