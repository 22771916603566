import React, { useState } from 'react';
import './App.css';
import SimpleBottomNavigation from './components/navigation';
import PlaylistTable from './components/playlistTable';
import UserlistTable from './components/userlistTable';
import TaglistTable from './components/taglistTable';
import CategorylistTable from './components/categorylistTable';
import VideolistTable from './components/videolistTable';

export interface AppProperties {
  display: number
}

function App(props: AppProperties) {
  const [display, setDisplay] = useState(props.display)

  const displayCallback = (display: number) => {
    setDisplay(display);
  }

  const renderTables = (display: number) => {
    switch(display) {
      case 0:
        return (<PlaylistTable playlists={[]}></PlaylistTable>)
      case 1:
        return (<UserlistTable userlist={[]}></UserlistTable>)
      case 2: 
        return (<VideolistTable videos={[]}></VideolistTable>)
      case 3:
        return (<TaglistTable taglist={[]}></TaglistTable>)
      case 4:
        return (<CategorylistTable categorylist={[]}></CategorylistTable>)
      default:
        return (<div><h1>What the hell is going on!</h1></div>)
    }
  }

  return (
    <div className="App">
      <SimpleBottomNavigation displayCallback={displayCallback}></SimpleBottomNavigation>
      {renderTables(display)}
      <header className="App-header">
        
      </header>
      
    </div>
  );
}

export default App;
