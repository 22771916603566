import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ListIcon from '@mui/icons-material/List';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import StyleIcon from '@mui/icons-material/Style';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


export interface SimpleBottomNavigationProps {
  displayCallback: (display: number) => void;
}

export default function SimpleBottomNavigation(props: SimpleBottomNavigationProps) {
  const [value, setValue] = React.useState(0);

  return (
    <Box sx={{ width: 500 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          props.displayCallback(newValue);
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Playlist" icon={<ListIcon />} />
        <BottomNavigationAction label="Users" icon={<PeopleIcon />} />
        <BottomNavigationAction label="Videos" icon={<OndemandVideoIcon />} />
        <BottomNavigationAction label="Tags" icon={<StyleIcon />} />
        <BottomNavigationAction label="Categories" icon={<CategoryIcon />} />
      </BottomNavigation>
    </Box>
  );
}